<template>
  <div class="container">
    <a-page-header title="用户详情" @back="() => {$router.go(-1)}"></a-page-header>
    <div class="wrapper">
      <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" :class="{readonly: 'readonly'}">
        <a-divider orientation="left">基本信息</a-divider>
        <a-form-item :label="formData.role_id == 1 ? '公司法人' : (formData.role_id == 2 ? '服务商' : '真实姓名')" >
          <a-input :read-only="readonly" v-model="formData.real_name" type="text"  
          />
        </a-form-item>
        <a-form-item label="身份证号" v-if="formData.role_id == 4">
          <a-input :read-only="readonly" v-model="formData.id_code" type="text" 
          />
        </a-form-item>
        <a-form-item :label="formData.role_id == 1 ? '公司名称' : '服务商主体'" v-if="formData.role_id != 4">
          <a-input :read-only="readonly" v-model="formData.company_name" type="text" 
          />
        </a-form-item>
        <a-form-item label="统一信用代码" v-if="formData.role_id != 3">
          <a-input :read-only="readonly" v-model="formData.credit_code" type="text" 
          />
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input :read-only="readonly" v-model="formData.phone" type="text" 
          />
        </a-form-item>
        <template v-if="formData.role_id != 4">
          <a-form-item label="纳税人类型">
            <a-radio-group  v-model="formData.type" :read-only="readonly">
              <a-radio v-if="!readonly || formData.type == 0" :value="0">一般纳税人</a-radio>
              <a-radio v-if="!readonly || formData.type == 1" :value="1">小规模纳税人</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="行业类型">
            <a-input :read-only="readonly" v-model="formData.industry_type" type="text" 
            />
          </a-form-item>
          <!-- <a-form-item label="企业状态">
            <a-input :read-only="readonly" v-model="formData.company_status" type="text" 
            />
          </a-form-item>
          <a-form-item label="注册资本">
            <a-input :read-only="readonly" v-model="formData.money" type="text" 
            />
          </a-form-item>
          <a-form-item label="实缴资本">
            <a-input :read-only="readonly" v-model="formData.real_money" type="text" 
            />
          </a-form-item> -->
          <a-form-item label="注册地址">
            <a-input :read-only="readonly" v-model="formData.address" type="text" 
            />
          </a-form-item>
          <!-- <a-form-item label="上市状态">
            <a-input :read-only="readonly" v-model="formData.listed_status" type="text" 
            />
          </a-form-item> -->
          <a-form-item label="标签">
            <a-tag v-for="item in formData.lable_name" :key="item.id">{{item.name}}</a-tag>
          </a-form-item>
          <a-form-item label="服务商描述" v-if="formData.role_id == 2">
            <a-textarea
              :read-only="readonly"
               v-model="formData.introduction"
              type="text"
            />
          </a-form-item>
          <a-divider orientation="left">企业资质</a-divider>
          <a-form-item label="营业执照">
            <div class="img-item">
              <div class="img">
                <img v-if="formData.yyzz" :src="formData.yyzz" alt="营业执照" />
              </div>
            </div>
          </a-form-item>
          <a-divider orientation="left">联系方式</a-divider>
          <a-form-item label="企业联系人">
            <a-form-item style="width:170px;display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.yq_name"
                type="text"
              />
            </a-form-item>
            <a-form-item style="width:calc(100% - 170px);display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.yq_phone"
                type="text"
              />
            </a-form-item>
            <!-- <a-form-item style="width:100%;display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.yq_email"
                type="text"
              />
            </a-form-item> -->
          </a-form-item>
          <a-form-item label="财务联系人">
            <a-form-item style="width:170px;display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.cw_name"
                type="text"
              />
            </a-form-item>
            <a-form-item style="width:calc(100% - 170px);display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.cw_phone"
                type="text"
              />
            </a-form-item>
            <!-- <a-form-item style="width:100%;display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.cw_email"
                type="text"
              />
            </a-form-item> -->
          </a-form-item>
          <a-form-item label="邮寄地址">
            <a-form-item style="width:170px;display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.yj_name"
                type="text"
              />
            </a-form-item>
            <a-form-item style="width:calc(100% - 170px);display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.yj_phone"
                type="text"
              />
            </a-form-item>
            <a-form-item style="width:100%;display:inline-block;">
              <a-input
                :read-only="readonly"
                 v-model="formData.yj_address"
                type="text"
              />
            </a-form-item>
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input
              :read-only="readonly"
               v-model="formData.eamil"
              type="text"
            />
          </a-form-item>
        </template>
        <template v-if="formData.role_id == 2">
          <a-divider orientation="left">服务信息</a-divider>
          <a-form-item :colon="false" label="月度限额上限(人/元)">
            <!-- <table style="width: 100%;border:1px solid #e8e8e8;">
              <thead class="ant-table-thead">
                <tr>
                  <th>单笔限额上限(人/元)</th>
                  <th>每日限额上限(人/元)</th>
                  <th>月度限额上限(人/元)</th>
                  <th>年度限额上限(人/元)</th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr>
                  <td>{{formData.d_money}}</td>
                  <td>{{formData.day_money}}</td>
                  <td>{{formData.month_money}}</td>
                  <td>{{formData.year_money}}</td>
                </tr>
              </tbody>
            </table> -->
            <a-input
              :read-only="readonly"
               v-model="formData.month_money"
              type="text"
            />
          </a-form-item>
          <a-form-item label="含税服务费率">
            <a-input
              :read-only="readonly"
               v-model="formData.hs_money"
              type="text"
            />
          </a-form-item>
        </template>
        <a-divider orientation="left">开户信息</a-divider>
          <a-form-item label="开户银行">
            <a-input
              :read-only="readonly"
               v-model="formData.bank"
              type="text"
            />
          </a-form-item>
          <a-form-item label="银行账号">
            <a-input
              :read-only="readonly"
               v-model="formData.bank_code"
              type="text"
            />
          </a-form-item>
        <template v-if="formData.role_id == 1">
          <a-divider orientation="left">认证状态</a-divider>
          <a-form-item label="认证状态">
            <a-select :read-only="readonly" v-model="formData.status" :allowClear="true">
              <a-select-option :value="0">未认证</a-select-option>
              <a-select-option :value="1">已认证</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label=" " :colon="false" v-if="formData.status == 0 && formData.role_id == 1 && userInfo.role_id == 0">
            <a-popconfirm title="确定要通过审核吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleSubmit">
              <a-button type="primary" icon="check-circle">通过审核</a-button>
            </a-popconfirm>
          </a-form-item>
        </template>
      </a-form>
    </div>
  </div>
</template>

<script>

import { userInfo } from '@/utils/api'
import { mapState } from 'vuex'
export default {
  name: 'UserDetail',
  data() {
    return {
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this),
      formData: {},
      readonly: true
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleSubmit() {
      const id = this.$route.query.id
      userInfo({
        id,
        switch: 2,
        status: 1
      }).then(() => {
        this.$message.success('修改成功')
        this.getData()
      })
    },
    getData() {
      const id = this.$route.query.id
      userInfo({
        id,
        switch: 1
      }).then(res => {
        const data = res.info
        this.formData = data
        // for (const key in data) {
        //   if (Object.hasOwnProperty.call(data, key)) {
        //     let item = data[key]
        //     this.form.getFieldDecorator(key, {initialValue: item})
        //   }
        // }
        // setTimeout(() => {
        //   this.form.setFieldsValue(data)
        // }, 1000)
        
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
  /deep/.ant-form.readonly {
    .ant-form-item-required {
      &::before {
        content: '';
      }
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  input[readonly=true] {
    border: none;
    outline: none;
    cursor: auto;
    &:focus {
      box-shadow: none;
    }
  }
  .img-item {
    width: 160px;
    height: 200px;
    border: 2px dotted #f6f6f6;
    border-radius: 6px;
    padding: 6px;
    background-color: #f9f9f9;
    .img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
